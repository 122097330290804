@import url("https://themes.googleusercontent.com/fonts/css?kit=hh9VFM0qOK4TrLidDd__Ui5bnSrpY5TE94xSqAiQW93OfsfM6rvuuu7h1pY3r_-A");

.terms-content {
    .lst-kix_su3cw9ujxk12-2>li {
        counter-increment: lst-ctn-kix_su3cw9ujxk12-2;
    }

    ol.lst-kix_g0wb47k0ykcf-0.start {
        counter-reset: lst-ctn-kix_g0wb47k0ykcf-0 10;
    }

    ol.lst-kix_3i7tngtwyogn-6.start {
        counter-reset: lst-ctn-kix_3i7tngtwyogn-6 0;
    }

    ol.lst-kix_su3cw9ujxk12-2.start {
        counter-reset: lst-ctn-kix_su3cw9ujxk12-2 0;
    }

    ol.lst-kix_aax8fik85dck-4.start {
        counter-reset: lst-ctn-kix_aax8fik85dck-4 0;
    }

    .lst-kix_xlfsbi2sm0px-5>li {
        counter-increment: lst-ctn-kix_xlfsbi2sm0px-5;
    }

    ol.lst-kix_hyo15tavnzmq-8 {
        list-style-type: none;
    }

    .lst-kix_1f2sx3bf4x8-0>li {
        counter-increment: lst-ctn-kix_1f2sx3bf4x8-0;
    }

    ol.lst-kix_hyo15tavnzmq-2 {
        list-style-type: none;
    }

    ol.lst-kix_hyo15tavnzmq-3 {
        list-style-type: none;
    }

    .lst-kix_nz0yms2x52g4-7>li {
        counter-increment: lst-ctn-kix_nz0yms2x52g4-7;
    }

    ol.lst-kix_hyo15tavnzmq-0 {
        list-style-type: none;
    }

    ol.lst-kix_hyo15tavnzmq-1 {
        list-style-type: none;
    }

    ol.lst-kix_hyo15tavnzmq-6 {
        list-style-type: none;
    }

    ol.lst-kix_hyo15tavnzmq-7 {
        list-style-type: none;
    }

    ol.lst-kix_hyo15tavnzmq-4 {
        list-style-type: none;
    }

    ol.lst-kix_hyo15tavnzmq-5 {
        list-style-type: none;
    }

    ol.lst-kix_nz0yms2x52g4-2.start {
        counter-reset: lst-ctn-kix_nz0yms2x52g4-2 0;
    }

    .lst-kix_vpxf4k7k6jyd-2>li {
        counter-increment: lst-ctn-kix_vpxf4k7k6jyd-2;
    }

    .lst-kix_nh1bdi7ymzg-5>li {
        counter-increment: lst-ctn-kix_nh1bdi7ymzg-5;
    }

    ol.lst-kix_xlfsbi2sm0px-6.start {
        counter-reset: lst-ctn-kix_xlfsbi2sm0px-6 0;
    }

    ol.lst-kix_hyo15tavnzmq-1.start {
        counter-reset: lst-ctn-kix_hyo15tavnzmq-1 0;
    }

    ol.lst-kix_nh1bdi7ymzg-7.start {
        counter-reset: lst-ctn-kix_nh1bdi7ymzg-7 0;
    }

    .lst-kix_vpxf4k7k6jyd-0>li:before {
        content: "(" counter(lst-ctn-kix_vpxf4k7k6jyd-0, lower-roman) ") ";
    }

    .lst-kix_vpxf4k7k6jyd-2>li:before {
        content: "(" counter(lst-ctn-kix_vpxf4k7k6jyd-2, lower-roman) ") ";
    }

    .lst-kix_u6orge8dsaaj-4>li {
        counter-increment: lst-ctn-kix_u6orge8dsaaj-4;
    }

    .lst-kix_vpxf4k7k6jyd-1>li:before {
        content: "(" counter(lst-ctn-kix_vpxf4k7k6jyd-1, lower-roman) ") ";
    }

    ol.lst-kix_hyo15tavnzmq-8.start {
        counter-reset: lst-ctn-kix_hyo15tavnzmq-8 0;
    }

    .lst-kix_vpxf4k7k6jyd-4>li:before {
        content: "(" counter(lst-ctn-kix_vpxf4k7k6jyd-4, lower-roman) ") ";
    }

    ol.lst-kix_nh1bdi7ymzg-0.start {
        counter-reset: lst-ctn-kix_nh1bdi7ymzg-0 0;
    }

    .lst-kix_vpxf4k7k6jyd-3>li:before {
        content: "(" counter(lst-ctn-kix_vpxf4k7k6jyd-3, lower-roman) ") ";
    }

    ol.lst-kix_3i7tngtwyogn-1.start {
        counter-reset: lst-ctn-kix_3i7tngtwyogn-1 0;
    }

    .lst-kix_vpxf4k7k6jyd-8>li:before {
        content: "(" counter(lst-ctn-kix_vpxf4k7k6jyd-8, lower-roman) ") ";
    }

    .lst-kix_vpxf4k7k6jyd-5>li:before {
        content: "(" counter(lst-ctn-kix_vpxf4k7k6jyd-5, lower-roman) ") ";
    }

    .lst-kix_vpxf4k7k6jyd-6>li:before {
        content: "(" counter(lst-ctn-kix_vpxf4k7k6jyd-6, lower-roman) ") ";
    }

    .lst-kix_g0wb47k0ykcf-7>li {
        counter-increment: lst-ctn-kix_g0wb47k0ykcf-7;
    }

    .lst-kix_vpxf4k7k6jyd-7>li:before {
        content: "(" counter(lst-ctn-kix_vpxf4k7k6jyd-7, lower-roman) ") ";
    }

    ol.lst-kix_vpxf4k7k6jyd-1.start {
        counter-reset: lst-ctn-kix_vpxf4k7k6jyd-1 0;
    }

    .lst-kix_hyo15tavnzmq-1>li {
        counter-increment: lst-ctn-kix_hyo15tavnzmq-1;
    }

    ol.lst-kix_u6orge8dsaaj-4.start {
        counter-reset: lst-ctn-kix_u6orge8dsaaj-4 0;
    }

    .lst-kix_nz0yms2x52g4-3>li {
        counter-increment: lst-ctn-kix_nz0yms2x52g4-3;
    }

    .lst-kix_3i7tngtwyogn-7>li {
        counter-increment: lst-ctn-kix_3i7tngtwyogn-7;
    }

    ol.lst-kix_vpxf4k7k6jyd-8.start {
        counter-reset: lst-ctn-kix_vpxf4k7k6jyd-8 0;
    }

    ol.lst-kix_nz0yms2x52g4-7.start {
        counter-reset: lst-ctn-kix_nz0yms2x52g4-7 0;
    }

    .lst-kix_aax8fik85dck-5>li {
        counter-increment: lst-ctn-kix_aax8fik85dck-5;
    }

    ol.lst-kix_xlfsbi2sm0px-1.start {
        counter-reset: lst-ctn-kix_xlfsbi2sm0px-1 0;
    }

    ol.lst-kix_xlfsbi2sm0px-8.start {
        counter-reset: lst-ctn-kix_xlfsbi2sm0px-8 0;
    }

    ol.lst-kix_g0wb47k0ykcf-7.start {
        counter-reset: lst-ctn-kix_g0wb47k0ykcf-7 0;
    }

    .lst-kix_hyo15tavnzmq-5>li {
        counter-increment: lst-ctn-kix_hyo15tavnzmq-5;
    }

    .lst-kix_3i7tngtwyogn-3>li {
        counter-increment: lst-ctn-kix_3i7tngtwyogn-3;
    }

    .lst-kix_aax8fik85dck-2>li {
        counter-increment: lst-ctn-kix_aax8fik85dck-2;
    }

    .lst-kix_aax8fik85dck-8>li:before {
        content: "" counter(lst-ctn-kix_aax8fik85dck-0, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-1, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-2, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-3, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-4, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-5, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-6, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-7, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-8, decimal) ". ";
    }

    .lst-kix_hyo15tavnzmq-1>li:before {
        content: "" counter(lst-ctn-kix_hyo15tavnzmq-0, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-1, decimal) ". ";
    }

    .lst-kix_aax8fik85dck-6>li:before {
        content: "" counter(lst-ctn-kix_aax8fik85dck-0, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-1, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-2, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-3, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-4, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-5, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-6, decimal) ". ";
    }

    .lst-kix_g0wb47k0ykcf-4>li {
        counter-increment: lst-ctn-kix_g0wb47k0ykcf-4;
    }

    .lst-kix_aax8fik85dck-2>li:before {
        content: "" counter(lst-ctn-kix_aax8fik85dck-0, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-1, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-2, decimal) ". ";
    }

    ol.lst-kix_vpxf4k7k6jyd-6.start {
        counter-reset: lst-ctn-kix_vpxf4k7k6jyd-6 0;
    }

    .lst-kix_aax8fik85dck-0>li:before {
        content: "" counter(lst-ctn-kix_aax8fik85dck-0, decimal) ". ";
    }

    .lst-kix_aax8fik85dck-4>li:before {
        content: "" counter(lst-ctn-kix_aax8fik85dck-0, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-1, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-2, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-3, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-4, decimal) ". ";
    }

    .lst-kix_xlfsbi2sm0px-1>li {
        counter-increment: lst-ctn-kix_xlfsbi2sm0px-1;
    }

    ol.lst-kix_aax8fik85dck-2.start {
        counter-reset: lst-ctn-kix_aax8fik85dck-2 0;
    }

    .lst-kix_fsqokrgcevtn-1>li:before {
        content: "(" counter(lst-ctn-kix_fsqokrgcevtn-1, lower-roman) ") ";
    }

    ol.lst-kix_vpxf4k7k6jyd-3.start {
        counter-reset: lst-ctn-kix_vpxf4k7k6jyd-3 0;
    }

    ol.lst-kix_fsqokrgcevtn-2.start {
        counter-reset: lst-ctn-kix_fsqokrgcevtn-2 0;
    }

    ol.lst-kix_3i7tngtwyogn-8.start {
        counter-reset: lst-ctn-kix_3i7tngtwyogn-8 0;
    }

    .lst-kix_1f2sx3bf4x8-7>li {
        counter-increment: lst-ctn-kix_1f2sx3bf4x8-7;
    }

    .lst-kix_nz0yms2x52g4-3>li:before {
        content: "(" counter(lst-ctn-kix_nz0yms2x52g4-3, lower-roman) ") ";
    }

    .lst-kix_nz0yms2x52g4-1>li:before {
        content: "(" counter(lst-ctn-kix_nz0yms2x52g4-1, lower-roman) ") ";
    }

    ol.lst-kix_su3cw9ujxk12-0.start {
        counter-reset: lst-ctn-kix_su3cw9ujxk12-0 7;
    }

    ol.lst-kix_aax8fik85dck-8 {
        list-style-type: none;
    }

    .lst-kix_su3cw9ujxk12-6>li {
        counter-increment: lst-ctn-kix_su3cw9ujxk12-6;
    }

    ol.lst-kix_u6orge8dsaaj-1.start {
        counter-reset: lst-ctn-kix_u6orge8dsaaj-1 0;
    }

    ol.lst-kix_aax8fik85dck-1 {
        list-style-type: none;
    }

    ol.lst-kix_nz0yms2x52g4-4.start {
        counter-reset: lst-ctn-kix_nz0yms2x52g4-4 0;
    }

    ol.lst-kix_aax8fik85dck-0 {
        list-style-type: none;
    }

    ol.lst-kix_aax8fik85dck-3 {
        list-style-type: none;
    }

    ol.lst-kix_aax8fik85dck-2 {
        list-style-type: none;
    }

    ol.lst-kix_aax8fik85dck-5 {
        list-style-type: none;
    }

    ol.lst-kix_aax8fik85dck-4 {
        list-style-type: none;
    }

    ol.lst-kix_aax8fik85dck-7 {
        list-style-type: none;
    }

    .lst-kix_fsqokrgcevtn-3>li {
        counter-increment: lst-ctn-kix_fsqokrgcevtn-3;
    }

    .lst-kix_g0wb47k0ykcf-3>li {
        counter-increment: lst-ctn-kix_g0wb47k0ykcf-3;
    }

    ol.lst-kix_aax8fik85dck-6 {
        list-style-type: none;
    }

    .lst-kix_vpxf4k7k6jyd-3>li {
        counter-increment: lst-ctn-kix_vpxf4k7k6jyd-3;
    }

    .lst-kix_u6orge8dsaaj-3>li {
        counter-increment: lst-ctn-kix_u6orge8dsaaj-3;
    }

    ol.lst-kix_g0wb47k0ykcf-5.start {
        counter-reset: lst-ctn-kix_g0wb47k0ykcf-5 0;
    }

    ol.lst-kix_fsqokrgcevtn-4.start {
        counter-reset: lst-ctn-kix_fsqokrgcevtn-4 0;
    }

    ol.lst-kix_su3cw9ujxk12-5.start {
        counter-reset: lst-ctn-kix_su3cw9ujxk12-5 0;
    }

    .lst-kix_xlfsbi2sm0px-6>li {
        counter-increment: lst-ctn-kix_xlfsbi2sm0px-6;
    }

    .lst-kix_g0wb47k0ykcf-0>li:before {
        content: "" counter(lst-ctn-kix_g0wb47k0ykcf-0, decimal) ". ";
    }

    .lst-kix_g0wb47k0ykcf-1>li:before {
        content: "" counter(lst-ctn-kix_g0wb47k0ykcf-0, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-1, decimal) ". ";
    }

    .lst-kix_g0wb47k0ykcf-4>li:before {
        content: "" counter(lst-ctn-kix_g0wb47k0ykcf-0, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-1, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-2, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-3, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-4, decimal) ". ";
    }

    ol.lst-kix_nz0yms2x52g4-8 {
        list-style-type: none;
    }

    .lst-kix_xlfsbi2sm0px-4>li {
        counter-increment: lst-ctn-kix_xlfsbi2sm0px-4;
    }

    .lst-kix_nz0yms2x52g4-4>li {
        counter-increment: lst-ctn-kix_nz0yms2x52g4-4;
    }

    ol.lst-kix_nz0yms2x52g4-6 {
        list-style-type: none;
    }

    ol.lst-kix_hyo15tavnzmq-4.start {
        counter-reset: lst-ctn-kix_hyo15tavnzmq-4 0;
    }

    ol.lst-kix_nz0yms2x52g4-7 {
        list-style-type: none;
    }

    ol.lst-kix_nz0yms2x52g4-4 {
        list-style-type: none;
    }

    ol.lst-kix_nz0yms2x52g4-5 {
        list-style-type: none;
    }

    ol.lst-kix_g0wb47k0ykcf-3.start {
        counter-reset: lst-ctn-kix_g0wb47k0ykcf-3 0;
    }

    ol.lst-kix_nz0yms2x52g4-2 {
        list-style-type: none;
    }

    ol.lst-kix_nz0yms2x52g4-3 {
        list-style-type: none;
    }

    ol.lst-kix_nz0yms2x52g4-0 {
        list-style-type: none;
    }

    ol.lst-kix_nz0yms2x52g4-1 {
        list-style-type: none;
    }

    .lst-kix_3i7tngtwyogn-8>li {
        counter-increment: lst-ctn-kix_3i7tngtwyogn-8;
    }

    .lst-kix_g0wb47k0ykcf-5>li:before {
        content: "" counter(lst-ctn-kix_g0wb47k0ykcf-0, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-1, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-2, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-3, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-4, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-5, decimal) ". ";
    }

    .lst-kix_nz0yms2x52g4-5>li:before {
        content: "(" counter(lst-ctn-kix_nz0yms2x52g4-5, lower-roman) ") ";
    }

    .lst-kix_su3cw9ujxk12-1>li {
        counter-increment: lst-ctn-kix_su3cw9ujxk12-1;
    }

    .lst-kix_nz0yms2x52g4-6>li:before {
        content: "(" counter(lst-ctn-kix_nz0yms2x52g4-6, lower-roman) ") ";
    }

    .lst-kix_vpxf4k7k6jyd-5>li {
        counter-increment: lst-ctn-kix_vpxf4k7k6jyd-5;
    }

    .lst-kix_g0wb47k0ykcf-8>li:before {
        content: "" counter(lst-ctn-kix_g0wb47k0ykcf-0, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-1, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-2, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-3, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-4, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-5, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-6, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-7, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-8, decimal) ". ";
    }

    .lst-kix_fsqokrgcevtn-2>li {
        counter-increment: lst-ctn-kix_fsqokrgcevtn-2;
    }

    .lst-kix_nh1bdi7ymzg-0>li:before {
        content: "" counter(lst-ctn-kix_nh1bdi7ymzg-0, decimal) ". ";
    }

    ol.lst-kix_g0wb47k0ykcf-2.start {
        counter-reset: lst-ctn-kix_g0wb47k0ykcf-2 0;
    }

    ol.lst-kix_nz0yms2x52g4-0.start {
        counter-reset: lst-ctn-kix_nz0yms2x52g4-0 0;
    }

    .lst-kix_fsqokrgcevtn-6>li:before {
        content: "(" counter(lst-ctn-kix_fsqokrgcevtn-6, lower-roman) ") ";
    }

    .lst-kix_fsqokrgcevtn-5>li:before {
        content: "(" counter(lst-ctn-kix_fsqokrgcevtn-5, lower-roman) ") ";
    }

    .lst-kix_nh1bdi7ymzg-3>li:before {
        content: "" counter(lst-ctn-kix_nh1bdi7ymzg-1, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-2, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-3, decimal) ". ";
    }

    .lst-kix_nh1bdi7ymzg-4>li:before {
        content: "" counter(lst-ctn-kix_nh1bdi7ymzg-1, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-2, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-3, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-4, decimal) ". ";
    }

    .lst-kix_su3cw9ujxk12-5>li {
        counter-increment: lst-ctn-kix_su3cw9ujxk12-5;
    }

    ol.lst-kix_aax8fik85dck-6.start {
        counter-reset: lst-ctn-kix_aax8fik85dck-6 0;
    }

    ol.lst-kix_fsqokrgcevtn-1.start {
        counter-reset: lst-ctn-kix_fsqokrgcevtn-1 0;
    }

    .lst-kix_1f2sx3bf4x8-8>li {
        counter-increment: lst-ctn-kix_1f2sx3bf4x8-8;
    }

    .lst-kix_3i7tngtwyogn-3>li:before {
        content: "" counter(lst-ctn-kix_3i7tngtwyogn-3, decimal) ". ";
    }

    .lst-kix_3i7tngtwyogn-0>li:before {
        content: "(" counter(lst-ctn-kix_3i7tngtwyogn-0, lower-roman) ") ";
    }

    .lst-kix_xlfsbi2sm0px-2>li {
        counter-increment: lst-ctn-kix_xlfsbi2sm0px-2;
    }

    .lst-kix_vpxf4k7k6jyd-7>li {
        counter-increment: lst-ctn-kix_vpxf4k7k6jyd-7;
    }

    .lst-kix_1f2sx3bf4x8-6>li:before {
        content: "" counter(lst-ctn-kix_1f2sx3bf4x8-0, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-1, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-2, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-3, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-4, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-5, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-6, decimal) ". ";
    }

    .lst-kix_1f2sx3bf4x8-5>li:before {
        content: "" counter(lst-ctn-kix_1f2sx3bf4x8-0, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-1, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-2, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-3, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-4, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-5, decimal) ". ";
    }

    .lst-kix_u6orge8dsaaj-5>li {
        counter-increment: lst-ctn-kix_u6orge8dsaaj-5;
    }

    .lst-kix_hyo15tavnzmq-5>li:before {
        content: "" counter(lst-ctn-kix_hyo15tavnzmq-0, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-1, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-2, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-3, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-4, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-5, decimal) ". ";
    }

    ol.lst-kix_fsqokrgcevtn-0.start {
        counter-reset: lst-ctn-kix_fsqokrgcevtn-0 0;
    }

    ol.lst-kix_su3cw9ujxk12-4.start {
        counter-reset: lst-ctn-kix_su3cw9ujxk12-4 0;
    }

    ol.lst-kix_aax8fik85dck-7.start {
        counter-reset: lst-ctn-kix_aax8fik85dck-7 0;
    }

    .lst-kix_hyo15tavnzmq-6>li:before {
        content: "" counter(lst-ctn-kix_hyo15tavnzmq-0, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-1, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-2, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-3, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-4, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-5, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-6, decimal) ". ";
    }

    .lst-kix_1f2sx3bf4x8-2>li:before {
        content: "" counter(lst-ctn-kix_1f2sx3bf4x8-0, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-1, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-2, decimal) ". ";
    }

    .lst-kix_1f2sx3bf4x8-1>li:before {
        content: "" counter(lst-ctn-kix_1f2sx3bf4x8-0, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-1, decimal) ". ";
    }

    .lst-kix_nz0yms2x52g4-6>li {
        counter-increment: lst-ctn-kix_nz0yms2x52g4-6;
    }

    .lst-kix_3i7tngtwyogn-4>li:before {
        content: "" counter(lst-ctn-kix_3i7tngtwyogn-4, lower-latin) ". ";
    }

    .lst-kix_nh1bdi7ymzg-7>li:before {
        content: "" counter(lst-ctn-kix_nh1bdi7ymzg-1, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-2, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-3, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-4, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-5, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-6, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-7, decimal) ". ";
    }

    .lst-kix_nh1bdi7ymzg-8>li:before {
        content: "" counter(lst-ctn-kix_nh1bdi7ymzg-1, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-2, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-3, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-4, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-5, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-6, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-7, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-8, decimal) ". ";
    }

    .lst-kix_3i7tngtwyogn-8>li:before {
        content: "" counter(lst-ctn-kix_3i7tngtwyogn-8, lower-roman) ". ";
    }

    .lst-kix_3i7tngtwyogn-7>li:before {
        content: "" counter(lst-ctn-kix_3i7tngtwyogn-7, lower-latin) ". ";
    }

    .lst-kix_3i7tngtwyogn-1>li {
        counter-increment: lst-ctn-kix_3i7tngtwyogn-1;
    }

    .lst-kix_3i7tngtwyogn-4>li {
        counter-increment: lst-ctn-kix_3i7tngtwyogn-4;
    }

    ol.lst-kix_hyo15tavnzmq-3.start {
        counter-reset: lst-ctn-kix_hyo15tavnzmq-3 0;
    }

    ol.lst-kix_su3cw9ujxk12-1 {
        list-style-type: none;
    }

    ol.lst-kix_su3cw9ujxk12-0 {
        list-style-type: none;
    }

    ol.lst-kix_1f2sx3bf4x8-2 {
        list-style-type: none;
    }

    ol.lst-kix_3i7tngtwyogn-2 {
        list-style-type: none;
    }

    ol.lst-kix_1f2sx3bf4x8-3 {
        list-style-type: none;
    }

    ol.lst-kix_3i7tngtwyogn-1 {
        list-style-type: none;
    }

    ol.lst-kix_1f2sx3bf4x8-0 {
        list-style-type: none;
    }

    ol.lst-kix_3i7tngtwyogn-0 {
        list-style-type: none;
    }

    .lst-kix_fsqokrgcevtn-5>li {
        counter-increment: lst-ctn-kix_fsqokrgcevtn-5;
    }

    ol.lst-kix_1f2sx3bf4x8-1 {
        list-style-type: none;
    }

    ol.lst-kix_su3cw9ujxk12-7 {
        list-style-type: none;
    }

    ol.lst-kix_3i7tngtwyogn-6 {
        list-style-type: none;
    }

    ol.lst-kix_su3cw9ujxk12-6 {
        list-style-type: none;
    }

    ol.lst-kix_3i7tngtwyogn-5 {
        list-style-type: none;
    }

    ol.lst-kix_3i7tngtwyogn-4 {
        list-style-type: none;
    }

    ol.lst-kix_su3cw9ujxk12-8 {
        list-style-type: none;
    }

    ol.lst-kix_3i7tngtwyogn-3 {
        list-style-type: none;
    }

    ol.lst-kix_su3cw9ujxk12-3 {
        list-style-type: none;
    }

    .lst-kix_u6orge8dsaaj-6>li:before {
        content: "(" counter(lst-ctn-kix_u6orge8dsaaj-6, lower-roman) ") ";
    }

    ol.lst-kix_su3cw9ujxk12-2 {
        list-style-type: none;
    }

    ol.lst-kix_su3cw9ujxk12-5 {
        list-style-type: none;
    }

    .lst-kix_su3cw9ujxk12-8>li {
        counter-increment: lst-ctn-kix_su3cw9ujxk12-8;
    }

    ol.lst-kix_3i7tngtwyogn-8 {
        list-style-type: none;
    }

    ol.lst-kix_su3cw9ujxk12-4 {
        list-style-type: none;
    }

    ol.lst-kix_3i7tngtwyogn-7 {
        list-style-type: none;
    }

    .lst-kix_1f2sx3bf4x8-5>li {
        counter-increment: lst-ctn-kix_1f2sx3bf4x8-5;
    }

    .lst-kix_nh1bdi7ymzg-0>li {
        counter-increment: lst-ctn-kix_nh1bdi7ymzg-0;
    }

    .lst-kix_hyo15tavnzmq-2>li:before {
        content: "" counter(lst-ctn-kix_hyo15tavnzmq-0, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-1, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-2, decimal) ". ";
    }

    ol.lst-kix_1f2sx3bf4x8-6 {
        list-style-type: none;
    }

    ol.lst-kix_1f2sx3bf4x8-7 {
        list-style-type: none;
    }

    ol.lst-kix_1f2sx3bf4x8-4 {
        list-style-type: none;
    }

    ol.lst-kix_1f2sx3bf4x8-5 {
        list-style-type: none;
    }

    .lst-kix_u6orge8dsaaj-2>li:before {
        content: "(" counter(lst-ctn-kix_u6orge8dsaaj-2, lower-roman) ") ";
    }

    ol.lst-kix_1f2sx3bf4x8-8 {
        list-style-type: none;
    }

    .lst-kix_aax8fik85dck-5>li:before {
        content: "" counter(lst-ctn-kix_aax8fik85dck-0, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-1, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-2, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-3, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-4, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-5, decimal) ". ";
    }

    ol.lst-kix_g0wb47k0ykcf-1.start {
        counter-reset: lst-ctn-kix_g0wb47k0ykcf-1 0;
    }

    ol.lst-kix_su3cw9ujxk12-6.start {
        counter-reset: lst-ctn-kix_su3cw9ujxk12-6 0;
    }

    .lst-kix_aax8fik85dck-1>li:before {
        content: "" counter(lst-ctn-kix_aax8fik85dck-0, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-1, decimal) ". ";
    }

    .lst-kix_hyo15tavnzmq-3>li {
        counter-increment: lst-ctn-kix_hyo15tavnzmq-3;
    }

    .lst-kix_g0wb47k0ykcf-5>li {
        counter-increment: lst-ctn-kix_g0wb47k0ykcf-5;
    }

    .lst-kix_fsqokrgcevtn-2>li:before {
        content: "(" counter(lst-ctn-kix_fsqokrgcevtn-2, lower-roman) ") ";
    }

    .lst-kix_nh1bdi7ymzg-2>li {
        counter-increment: lst-ctn-kix_nh1bdi7ymzg-2;
    }

    .lst-kix_u6orge8dsaaj-7>li {
        counter-increment: lst-ctn-kix_u6orge8dsaaj-7;
    }

    .lst-kix_1f2sx3bf4x8-3>li {
        counter-increment: lst-ctn-kix_1f2sx3bf4x8-3;
    }

    .lst-kix_fsqokrgcevtn-7>li {
        counter-increment: lst-ctn-kix_fsqokrgcevtn-7;
    }

    ol.lst-kix_aax8fik85dck-8.start {
        counter-reset: lst-ctn-kix_aax8fik85dck-8 0;
    }

    ol.lst-kix_vpxf4k7k6jyd-0.start {
        counter-reset: lst-ctn-kix_vpxf4k7k6jyd-0 0;
    }

    .lst-kix_xlfsbi2sm0px-5>li:before {
        content: "" counter(lst-ctn-kix_xlfsbi2sm0px-1, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-2, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-3, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-4, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-5, decimal) ". ";
    }

    .lst-kix_hyo15tavnzmq-8>li {
        counter-increment: lst-ctn-kix_hyo15tavnzmq-8;
    }

    ol.lst-kix_su3cw9ujxk12-7.start {
        counter-reset: lst-ctn-kix_su3cw9ujxk12-7 0;
    }

    .lst-kix_nz0yms2x52g4-2>li:before {
        content: "(" counter(lst-ctn-kix_nz0yms2x52g4-2, lower-roman) ") ";
    }

    .lst-kix_3i7tngtwyogn-6>li {
        counter-increment: lst-ctn-kix_3i7tngtwyogn-6;
    }

    ol.lst-kix_hyo15tavnzmq-5.start {
        counter-reset: lst-ctn-kix_hyo15tavnzmq-5 0;
    }

    .lst-kix_su3cw9ujxk12-8>li:before {
        content: "" counter(lst-ctn-kix_su3cw9ujxk12-0, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-1, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-2, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-3, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-4, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-5, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-6, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-7, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-8, decimal) ". ";
    }

    .lst-kix_aax8fik85dck-0>li {
        counter-increment: lst-ctn-kix_aax8fik85dck-0;
    }

    .lst-kix_g0wb47k0ykcf-0>li {
        counter-increment: lst-ctn-kix_g0wb47k0ykcf-0;
    }

    .lst-kix_nh1bdi7ymzg-7>li {
        counter-increment: lst-ctn-kix_nh1bdi7ymzg-7;
    }

    .lst-kix_vpxf4k7k6jyd-0>li {
        counter-increment: lst-ctn-kix_vpxf4k7k6jyd-0;
    }

    .lst-kix_su3cw9ujxk12-3>li {
        counter-increment: lst-ctn-kix_su3cw9ujxk12-3;
    }

    .lst-kix_su3cw9ujxk12-4>li:before {
        content: "" counter(lst-ctn-kix_su3cw9ujxk12-0, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-1, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-2, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-3, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-4, decimal) ". ";
    }

    .lst-kix_u6orge8dsaaj-0>li {
        counter-increment: lst-ctn-kix_u6orge8dsaaj-0;
    }

    ol.lst-kix_hyo15tavnzmq-6.start {
        counter-reset: lst-ctn-kix_hyo15tavnzmq-6 0;
    }

    .lst-kix_fsqokrgcevtn-0>li {
        counter-increment: lst-ctn-kix_fsqokrgcevtn-0;
    }

    .lst-kix_xlfsbi2sm0px-1>li:before {
        content: "" counter(lst-ctn-kix_xlfsbi2sm0px-1, decimal) ". ";
    }

    .lst-kix_su3cw9ujxk12-0>li:before {
        content: "" counter(lst-ctn-kix_su3cw9ujxk12-0, decimal) ". ";
    }

    .lst-kix_vpxf4k7k6jyd-4>li {
        counter-increment: lst-ctn-kix_vpxf4k7k6jyd-4;
    }

    .lst-kix_nz0yms2x52g4-5>li {
        counter-increment: lst-ctn-kix_nz0yms2x52g4-5;
    }

    ol.lst-kix_vpxf4k7k6jyd-2.start {
        counter-reset: lst-ctn-kix_vpxf4k7k6jyd-2 0;
    }

    .lst-kix_aax8fik85dck-7>li {
        counter-increment: lst-ctn-kix_aax8fik85dck-7;
    }

    ol.lst-kix_su3cw9ujxk12-8.start {
        counter-reset: lst-ctn-kix_su3cw9ujxk12-8 0;
    }

    ol.lst-kix_hyo15tavnzmq-7.start {
        counter-reset: lst-ctn-kix_hyo15tavnzmq-7 0;
    }

    ol.lst-kix_u6orge8dsaaj-5.start {
        counter-reset: lst-ctn-kix_u6orge8dsaaj-5 0;
    }

    ol.lst-kix_fsqokrgcevtn-3.start {
        counter-reset: lst-ctn-kix_fsqokrgcevtn-3 0;
    }

    .lst-kix_u6orge8dsaaj-2>li {
        counter-increment: lst-ctn-kix_u6orge8dsaaj-2;
    }

    ol.lst-kix_1f2sx3bf4x8-5.start {
        counter-reset: lst-ctn-kix_1f2sx3bf4x8-5 0;
    }

    ol.lst-kix_nh1bdi7ymzg-6.start {
        counter-reset: lst-ctn-kix_nh1bdi7ymzg-6 0;
    }

    ol.lst-kix_nz0yms2x52g4-8.start {
        counter-reset: lst-ctn-kix_nz0yms2x52g4-8 0;
    }

    .lst-kix_fsqokrgcevtn-1>li {
        counter-increment: lst-ctn-kix_fsqokrgcevtn-1;
    }

    .lst-kix_xlfsbi2sm0px-7>li {
        counter-increment: lst-ctn-kix_xlfsbi2sm0px-7;
    }

    .lst-kix_su3cw9ujxk12-4>li {
        counter-increment: lst-ctn-kix_su3cw9ujxk12-4;
    }

    .lst-kix_fsqokrgcevtn-8>li {
        counter-increment: lst-ctn-kix_fsqokrgcevtn-8;
    }

    ol.lst-kix_vpxf4k7k6jyd-7.start {
        counter-reset: lst-ctn-kix_vpxf4k7k6jyd-7 0;
    }

    ol.lst-kix_su3cw9ujxk12-3.start {
        counter-reset: lst-ctn-kix_su3cw9ujxk12-3 0;
    }

    .lst-kix_1f2sx3bf4x8-2>li {
        counter-increment: lst-ctn-kix_1f2sx3bf4x8-2;
    }

    .lst-kix_nh1bdi7ymzg-3>li {
        counter-increment: lst-ctn-kix_nh1bdi7ymzg-3;
    }

    ol.lst-kix_xlfsbi2sm0px-0.start {
        counter-reset: lst-ctn-kix_xlfsbi2sm0px-0 0;
    }

    ol.lst-kix_fsqokrgcevtn-8.start {
        counter-reset: lst-ctn-kix_fsqokrgcevtn-8 0;
    }

    ol.lst-kix_1f2sx3bf4x8-0.start {
        counter-reset: lst-ctn-kix_1f2sx3bf4x8-0 9;
    }

    ol.lst-kix_nh1bdi7ymzg-1.start {
        counter-reset: lst-ctn-kix_nh1bdi7ymzg-1 0;
    }

    ol.lst-kix_hyo15tavnzmq-2.start {
        counter-reset: lst-ctn-kix_hyo15tavnzmq-2 0;
    }

    ol.lst-kix_g0wb47k0ykcf-0 {
        list-style-type: none;
    }

    ol.lst-kix_g0wb47k0ykcf-1 {
        list-style-type: none;
    }

    ol.lst-kix_xlfsbi2sm0px-7.start {
        counter-reset: lst-ctn-kix_xlfsbi2sm0px-7 0;
    }

    ol.lst-kix_g0wb47k0ykcf-2 {
        list-style-type: none;
    }

    ol.lst-kix_g0wb47k0ykcf-3 {
        list-style-type: none;
    }

    li.li-bullet-2:before {
        margin-left: -33.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 33.4pt;
    }

    ol.lst-kix_g0wb47k0ykcf-4 {
        list-style-type: none;
    }

    ol.lst-kix_g0wb47k0ykcf-5 {
        list-style-type: none;
    }

    ol.lst-kix_g0wb47k0ykcf-6 {
        list-style-type: none;
    }

    ol.lst-kix_g0wb47k0ykcf-7 {
        list-style-type: none;
    }

    ol.lst-kix_g0wb47k0ykcf-8 {
        list-style-type: none;
    }

    ol.lst-kix_3i7tngtwyogn-0.start {
        counter-reset: lst-ctn-kix_3i7tngtwyogn-0 0;
    }

    .lst-kix_u6orge8dsaaj-7>li:before {
        content: "(" counter(lst-ctn-kix_u6orge8dsaaj-7, lower-roman) ") ";
    }

    .lst-kix_u6orge8dsaaj-5>li:before {
        content: "(" counter(lst-ctn-kix_u6orge8dsaaj-5, lower-roman) ") ";
    }

    .lst-kix_xlfsbi2sm0px-0>li {
        counter-increment: lst-ctn-kix_xlfsbi2sm0px-0;
    }

    ol.lst-kix_g0wb47k0ykcf-4.start {
        counter-reset: lst-ctn-kix_g0wb47k0ykcf-4 0;
    }

    .lst-kix_u6orge8dsaaj-1>li:before {
        content: "(" counter(lst-ctn-kix_u6orge8dsaaj-1, lower-roman) ") ";
    }

    .lst-kix_hyo15tavnzmq-6>li {
        counter-increment: lst-ctn-kix_hyo15tavnzmq-6;
    }

    .lst-kix_u6orge8dsaaj-3>li:before {
        content: "(" counter(lst-ctn-kix_u6orge8dsaaj-3, lower-roman) ") ";
    }

    ol.lst-kix_hyo15tavnzmq-0.start {
        counter-reset: lst-ctn-kix_hyo15tavnzmq-0 0;
    }

    ol.lst-kix_u6orge8dsaaj-6 {
        list-style-type: none;
    }

    ol.lst-kix_u6orge8dsaaj-7 {
        list-style-type: none;
    }

    ol.lst-kix_nz0yms2x52g4-1.start {
        counter-reset: lst-ctn-kix_nz0yms2x52g4-1 0;
    }

    ol.lst-kix_u6orge8dsaaj-8 {
        list-style-type: none;
    }

    ol.lst-kix_3i7tngtwyogn-7.start {
        counter-reset: lst-ctn-kix_3i7tngtwyogn-7 0;
    }

    ol.lst-kix_u6orge8dsaaj-2 {
        list-style-type: none;
    }

    ol.lst-kix_u6orge8dsaaj-3 {
        list-style-type: none;
    }

    ol.lst-kix_u6orge8dsaaj-4 {
        list-style-type: none;
    }

    ol.lst-kix_u6orge8dsaaj-5 {
        list-style-type: none;
    }

    .lst-kix_3i7tngtwyogn-2>li {
        counter-increment: lst-ctn-kix_3i7tngtwyogn-2;
    }

    ol.lst-kix_u6orge8dsaaj-0 {
        list-style-type: none;
    }

    ol.lst-kix_u6orge8dsaaj-1 {
        list-style-type: none;
    }

    li.li-bullet-1:before {
        margin-left: -33.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 33.4pt;
    }

    ol.lst-kix_fsqokrgcevtn-5.start {
        counter-reset: lst-ctn-kix_fsqokrgcevtn-5 0;
    }

    ol.lst-kix_aax8fik85dck-5.start {
        counter-reset: lst-ctn-kix_aax8fik85dck-5 0;
    }

    .lst-kix_vpxf4k7k6jyd-8>li {
        counter-increment: lst-ctn-kix_vpxf4k7k6jyd-8;
    }

    .lst-kix_su3cw9ujxk12-7>li {
        counter-increment: lst-ctn-kix_su3cw9ujxk12-7;
    }

    .lst-kix_g0wb47k0ykcf-2>li {
        counter-increment: lst-ctn-kix_g0wb47k0ykcf-2;
    }

    .lst-kix_1f2sx3bf4x8-6>li {
        counter-increment: lst-ctn-kix_1f2sx3bf4x8-6;
    }

    .lst-kix_fsqokrgcevtn-4>li {
        counter-increment: lst-ctn-kix_fsqokrgcevtn-4;
    }

    .lst-kix_xlfsbi2sm0px-6>li:before {
        content: "" counter(lst-ctn-kix_xlfsbi2sm0px-1, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-2, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-3, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-4, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-5, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-6, decimal) ". ";
    }

    .lst-kix_nz0yms2x52g4-8>li {
        counter-increment: lst-ctn-kix_nz0yms2x52g4-8;
    }

    .lst-kix_1f2sx3bf4x8-1>li {
        counter-increment: lst-ctn-kix_1f2sx3bf4x8-1;
    }

    .lst-kix_aax8fik85dck-4>li {
        counter-increment: lst-ctn-kix_aax8fik85dck-4;
    }

    .lst-kix_nz0yms2x52g4-2>li {
        counter-increment: lst-ctn-kix_nz0yms2x52g4-2;
    }

    .lst-kix_xlfsbi2sm0px-4>li:before {
        content: "" counter(lst-ctn-kix_xlfsbi2sm0px-1, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-2, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-3, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-4, decimal) ". ";
    }

    .lst-kix_su3cw9ujxk12-5>li:before {
        content: "" counter(lst-ctn-kix_su3cw9ujxk12-0, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-1, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-2, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-3, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-4, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-5, decimal) ". ";
    }

    .lst-kix_su3cw9ujxk12-7>li:before {
        content: "" counter(lst-ctn-kix_su3cw9ujxk12-0, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-1, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-2, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-3, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-4, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-5, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-6, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-7, decimal) ". ";
    }

    ol.lst-kix_nz0yms2x52g4-3.start {
        counter-reset: lst-ctn-kix_nz0yms2x52g4-3 0;
    }

    ol.lst-kix_g0wb47k0ykcf-6.start {
        counter-reset: lst-ctn-kix_g0wb47k0ykcf-6 0;
    }

    ol.lst-kix_vpxf4k7k6jyd-4.start {
        counter-reset: lst-ctn-kix_vpxf4k7k6jyd-4 0;
    }

    ol.lst-kix_u6orge8dsaaj-0.start {
        counter-reset: lst-ctn-kix_u6orge8dsaaj-0 0;
    }

    .lst-kix_nz0yms2x52g4-1>li {
        counter-increment: lst-ctn-kix_nz0yms2x52g4-1;
    }

    .lst-kix_xlfsbi2sm0px-8>li:before {
        content: "" counter(lst-ctn-kix_xlfsbi2sm0px-1, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-2, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-3, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-4, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-5, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-6, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-7, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-8, decimal) ". ";
    }

    .lst-kix_aax8fik85dck-3>li {
        counter-increment: lst-ctn-kix_aax8fik85dck-3;
    }

    .lst-kix_nh1bdi7ymzg-4>li {
        counter-increment: lst-ctn-kix_nh1bdi7ymzg-4;
    }

    .lst-kix_su3cw9ujxk12-3>li:before {
        content: "" counter(lst-ctn-kix_su3cw9ujxk12-0, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-1, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-2, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-3, decimal) ". ";
    }

    .lst-kix_su3cw9ujxk12-0>li {
        counter-increment: lst-ctn-kix_su3cw9ujxk12-0;
    }

    ol.lst-kix_aax8fik85dck-3.start {
        counter-reset: lst-ctn-kix_aax8fik85dck-3 0;
    }

    .lst-kix_xlfsbi2sm0px-2>li:before {
        content: "" counter(lst-ctn-kix_xlfsbi2sm0px-1, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-2, decimal) ". ";
    }

    ol.lst-kix_vpxf4k7k6jyd-5.start {
        counter-reset: lst-ctn-kix_vpxf4k7k6jyd-5 0;
    }

    .lst-kix_su3cw9ujxk12-1>li:before {
        content: "" counter(lst-ctn-kix_su3cw9ujxk12-0, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-1, decimal) ". ";
    }

    ol.lst-kix_su3cw9ujxk12-1.start {
        counter-reset: lst-ctn-kix_su3cw9ujxk12-1 0;
    }

    .lst-kix_xlfsbi2sm0px-0>li:before {
        content: "" counter(lst-ctn-kix_xlfsbi2sm0px-0, decimal) ". ";
    }

    ol.lst-kix_aax8fik85dck-1.start {
        counter-reset: lst-ctn-kix_aax8fik85dck-1 0;
    }

    ol.lst-kix_fsqokrgcevtn-6.start {
        counter-reset: lst-ctn-kix_fsqokrgcevtn-6 0;
    }

    ol.lst-kix_nz0yms2x52g4-5.start {
        counter-reset: lst-ctn-kix_nz0yms2x52g4-5 0;
    }

    .lst-kix_u6orge8dsaaj-1>li {
        counter-increment: lst-ctn-kix_u6orge8dsaaj-1;
    }

    .lst-kix_nh1bdi7ymzg-6>li {
        counter-increment: lst-ctn-kix_nh1bdi7ymzg-6;
    }

    ol.lst-kix_u6orge8dsaaj-2.start {
        counter-reset: lst-ctn-kix_u6orge8dsaaj-2 0;
    }

    ol.lst-kix_nh1bdi7ymzg-7 {
        list-style-type: none;
    }

    ol.lst-kix_nh1bdi7ymzg-8 {
        list-style-type: none;
    }

    ol.lst-kix_nh1bdi7ymzg-3 {
        list-style-type: none;
    }

    ol.lst-kix_nh1bdi7ymzg-4 {
        list-style-type: none;
    }

    ol.lst-kix_nh1bdi7ymzg-5 {
        list-style-type: none;
    }

    ol.lst-kix_nh1bdi7ymzg-6 {
        list-style-type: none;
    }

    ol.lst-kix_nh1bdi7ymzg-0 {
        list-style-type: none;
    }

    ol.lst-kix_nh1bdi7ymzg-1 {
        list-style-type: none;
    }

    ol.lst-kix_u6orge8dsaaj-8.start {
        counter-reset: lst-ctn-kix_u6orge8dsaaj-8 0;
    }

    ol.lst-kix_nh1bdi7ymzg-2 {
        list-style-type: none;
    }

    .lst-kix_g0wb47k0ykcf-2>li:before {
        content: "" counter(lst-ctn-kix_g0wb47k0ykcf-0, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-1, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-2, decimal) ". ";
    }

    .lst-kix_aax8fik85dck-6>li {
        counter-increment: lst-ctn-kix_aax8fik85dck-6;
    }

    .lst-kix_g0wb47k0ykcf-3>li:before {
        content: "" counter(lst-ctn-kix_g0wb47k0ykcf-0, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-1, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-2, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-3, decimal) ". ";
    }

    .lst-kix_g0wb47k0ykcf-7>li:before {
        content: "" counter(lst-ctn-kix_g0wb47k0ykcf-0, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-1, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-2, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-3, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-4, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-5, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-6, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-7, decimal) ". ";
    }

    ol.lst-kix_1f2sx3bf4x8-2.start {
        counter-reset: lst-ctn-kix_1f2sx3bf4x8-2 0;
    }

    .lst-kix_g0wb47k0ykcf-6>li:before {
        content: "" counter(lst-ctn-kix_g0wb47k0ykcf-0, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-1, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-2, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-3, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-4, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-5, decimal) "."
            counter(lst-ctn-kix_g0wb47k0ykcf-6, decimal) ". ";
    }

    ol.lst-kix_fsqokrgcevtn-6 {
        list-style-type: none;
    }

    ol.lst-kix_fsqokrgcevtn-7 {
        list-style-type: none;
    }

    ol.lst-kix_fsqokrgcevtn-8 {
        list-style-type: none;
    }

    .lst-kix_g0wb47k0ykcf-8>li {
        counter-increment: lst-ctn-kix_g0wb47k0ykcf-8;
    }

    .lst-kix_hyo15tavnzmq-0>li {
        counter-increment: lst-ctn-kix_hyo15tavnzmq-0;
    }

    ol.lst-kix_nh1bdi7ymzg-3.start {
        counter-reset: lst-ctn-kix_nh1bdi7ymzg-3 0;
    }

    .lst-kix_nz0yms2x52g4-8>li:before {
        content: "(" counter(lst-ctn-kix_nz0yms2x52g4-8, lower-roman) ") ";
    }

    ol.lst-kix_fsqokrgcevtn-0 {
        list-style-type: none;
    }

    .lst-kix_nz0yms2x52g4-7>li:before {
        content: "(" counter(lst-ctn-kix_nz0yms2x52g4-7, lower-roman) ") ";
    }

    ol.lst-kix_fsqokrgcevtn-1 {
        list-style-type: none;
    }

    ol.lst-kix_fsqokrgcevtn-2 {
        list-style-type: none;
    }

    ol.lst-kix_fsqokrgcevtn-3 {
        list-style-type: none;
    }

    ol.lst-kix_fsqokrgcevtn-4 {
        list-style-type: none;
    }

    ol.lst-kix_fsqokrgcevtn-5 {
        list-style-type: none;
    }

    .lst-kix_nh1bdi7ymzg-1>li:before {
        content: "" counter(lst-ctn-kix_nh1bdi7ymzg-1, decimal) ". ";
    }

    .lst-kix_g0wb47k0ykcf-1>li {
        counter-increment: lst-ctn-kix_g0wb47k0ykcf-1;
    }

    .lst-kix_vpxf4k7k6jyd-1>li {
        counter-increment: lst-ctn-kix_vpxf4k7k6jyd-1;
    }

    .lst-kix_xlfsbi2sm0px-8>li {
        counter-increment: lst-ctn-kix_xlfsbi2sm0px-8;
    }

    ol.lst-kix_1f2sx3bf4x8-1.start {
        counter-reset: lst-ctn-kix_1f2sx3bf4x8-1 0;
    }

    .lst-kix_fsqokrgcevtn-3>li:before {
        content: "(" counter(lst-ctn-kix_fsqokrgcevtn-3, lower-roman) ") ";
    }

    .lst-kix_nh1bdi7ymzg-2>li:before {
        content: "" counter(lst-ctn-kix_nh1bdi7ymzg-1, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-2, decimal) ". ";
    }

    .lst-kix_fsqokrgcevtn-4>li:before {
        content: "(" counter(lst-ctn-kix_fsqokrgcevtn-4, lower-roman) ") ";
    }

    .lst-kix_3i7tngtwyogn-2>li:before {
        content: "" counter(lst-ctn-kix_3i7tngtwyogn-2, lower-roman) ". ";
    }

    ol.lst-kix_u6orge8dsaaj-3.start {
        counter-reset: lst-ctn-kix_u6orge8dsaaj-3 0;
    }

    .lst-kix_3i7tngtwyogn-1>li:before {
        content: "" counter(lst-ctn-kix_3i7tngtwyogn-1, lower-latin) ". ";
    }

    .lst-kix_fsqokrgcevtn-7>li:before {
        content: "(" counter(lst-ctn-kix_fsqokrgcevtn-7, lower-roman) ") ";
    }

    ol.lst-kix_1f2sx3bf4x8-8.start {
        counter-reset: lst-ctn-kix_1f2sx3bf4x8-8 0;
    }

    ol.lst-kix_nz0yms2x52g4-6.start {
        counter-reset: lst-ctn-kix_nz0yms2x52g4-6 0;
    }

    .lst-kix_fsqokrgcevtn-8>li:before {
        content: "(" counter(lst-ctn-kix_fsqokrgcevtn-8, lower-roman) ") ";
    }

    .lst-kix_hyo15tavnzmq-7>li:before {
        content: "" counter(lst-ctn-kix_hyo15tavnzmq-0, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-1, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-2, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-3, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-4, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-5, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-6, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-7, decimal) ". ";
    }

    ol.lst-kix_fsqokrgcevtn-7.start {
        counter-reset: lst-ctn-kix_fsqokrgcevtn-7 0;
    }

    .lst-kix_1f2sx3bf4x8-7>li:before {
        content: "" counter(lst-ctn-kix_1f2sx3bf4x8-0, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-1, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-2, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-3, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-4, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-5, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-6, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-7, decimal) ". ";
    }

    .lst-kix_1f2sx3bf4x8-4>li:before {
        content: "" counter(lst-ctn-kix_1f2sx3bf4x8-0, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-1, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-2, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-3, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-4, decimal) ". ";
    }

    .lst-kix_1f2sx3bf4x8-8>li:before {
        content: "" counter(lst-ctn-kix_1f2sx3bf4x8-0, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-1, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-2, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-3, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-4, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-5, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-6, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-7, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-8, decimal) ". ";
    }

    ol.lst-kix_aax8fik85dck-0.start {
        counter-reset: lst-ctn-kix_aax8fik85dck-0 1;
    }

    .lst-kix_u6orge8dsaaj-8>li {
        counter-increment: lst-ctn-kix_u6orge8dsaaj-8;
    }

    .lst-kix_hyo15tavnzmq-3>li:before {
        content: "" counter(lst-ctn-kix_hyo15tavnzmq-0, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-1, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-2, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-3, decimal) ". ";
    }

    ol.lst-kix_nh1bdi7ymzg-8.start {
        counter-reset: lst-ctn-kix_nh1bdi7ymzg-8 0;
    }

    .lst-kix_1f2sx3bf4x8-3>li:before {
        content: "" counter(lst-ctn-kix_1f2sx3bf4x8-0, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-1, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-2, decimal) "."
            counter(lst-ctn-kix_1f2sx3bf4x8-3, decimal) ". ";
    }

    ol.lst-kix_1f2sx3bf4x8-7.start {
        counter-reset: lst-ctn-kix_1f2sx3bf4x8-7 0;
    }

    ol.lst-kix_g0wb47k0ykcf-8.start {
        counter-reset: lst-ctn-kix_g0wb47k0ykcf-8 0;
    }

    .lst-kix_hyo15tavnzmq-4>li:before {
        content: "" counter(lst-ctn-kix_hyo15tavnzmq-0, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-1, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-2, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-3, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-4, decimal) ". ";
    }

    .lst-kix_3i7tngtwyogn-5>li:before {
        content: "" counter(lst-ctn-kix_3i7tngtwyogn-5, lower-roman) ". ";
    }

    .lst-kix_aax8fik85dck-8>li {
        counter-increment: lst-ctn-kix_aax8fik85dck-8;
    }

    .lst-kix_3i7tngtwyogn-6>li:before {
        content: "" counter(lst-ctn-kix_3i7tngtwyogn-6, decimal) ". ";
    }

    .lst-kix_nh1bdi7ymzg-5>li:before {
        content: "" counter(lst-ctn-kix_nh1bdi7ymzg-1, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-2, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-3, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-4, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-5, decimal) ". ";
    }

    .lst-kix_nz0yms2x52g4-0>li {
        counter-increment: lst-ctn-kix_nz0yms2x52g4-0;
    }

    .lst-kix_hyo15tavnzmq-7>li {
        counter-increment: lst-ctn-kix_hyo15tavnzmq-7;
    }

    .lst-kix_hyo15tavnzmq-4>li {
        counter-increment: lst-ctn-kix_hyo15tavnzmq-4;
    }

    .lst-kix_nh1bdi7ymzg-6>li:before {
        content: "" counter(lst-ctn-kix_nh1bdi7ymzg-1, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-2, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-3, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-4, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-5, decimal) "."
            counter(lst-ctn-kix_nh1bdi7ymzg-6, decimal) ". ";
    }

    .lst-kix_hyo15tavnzmq-8>li:before {
        content: "" counter(lst-ctn-kix_hyo15tavnzmq-0, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-1, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-2, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-3, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-4, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-5, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-6, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-7, decimal) "."
            counter(lst-ctn-kix_hyo15tavnzmq-8, decimal) ". ";
    }

    .lst-kix_u6orge8dsaaj-8>li:before {
        content: "(" counter(lst-ctn-kix_u6orge8dsaaj-8, lower-roman) ") ";
    }

    ol.lst-kix_1f2sx3bf4x8-6.start {
        counter-reset: lst-ctn-kix_1f2sx3bf4x8-6 0;
    }

    .lst-kix_u6orge8dsaaj-4>li:before {
        content: "(" counter(lst-ctn-kix_u6orge8dsaaj-4, lower-roman) ") ";
    }

    .lst-kix_1f2sx3bf4x8-4>li {
        counter-increment: lst-ctn-kix_1f2sx3bf4x8-4;
    }

    ol.lst-kix_nh1bdi7ymzg-5.start {
        counter-reset: lst-ctn-kix_nh1bdi7ymzg-5 0;
    }

    .lst-kix_u6orge8dsaaj-6>li {
        counter-increment: lst-ctn-kix_u6orge8dsaaj-6;
    }

    ol.lst-kix_xlfsbi2sm0px-5.start {
        counter-reset: lst-ctn-kix_xlfsbi2sm0px-5 0;
    }

    .lst-kix_u6orge8dsaaj-0>li:before {
        content: "(" counter(lst-ctn-kix_u6orge8dsaaj-0, lower-roman) ") ";
    }

    .lst-kix_1f2sx3bf4x8-0>li:before {
        content: "" counter(lst-ctn-kix_1f2sx3bf4x8-0, decimal) ". ";
    }

    .lst-kix_aax8fik85dck-7>li:before {
        content: "" counter(lst-ctn-kix_aax8fik85dck-0, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-1, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-2, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-3, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-4, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-5, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-6, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-7, decimal) ". ";
    }

    ol.lst-kix_1f2sx3bf4x8-3.start {
        counter-reset: lst-ctn-kix_1f2sx3bf4x8-3 0;
    }

    ol.lst-kix_nh1bdi7ymzg-2.start {
        counter-reset: lst-ctn-kix_nh1bdi7ymzg-2 0;
    }

    .lst-kix_hyo15tavnzmq-0>li:before {
        content: "" counter(lst-ctn-kix_hyo15tavnzmq-0, decimal) ". ";
    }

    ol.lst-kix_3i7tngtwyogn-2.start {
        counter-reset: lst-ctn-kix_3i7tngtwyogn-2 0;
    }

    .lst-kix_3i7tngtwyogn-5>li {
        counter-increment: lst-ctn-kix_3i7tngtwyogn-5;
    }

    .lst-kix_aax8fik85dck-3>li:before {
        content: "" counter(lst-ctn-kix_aax8fik85dck-0, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-1, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-2, decimal) "."
            counter(lst-ctn-kix_aax8fik85dck-3, decimal) ". ";
    }

    ol.lst-kix_xlfsbi2sm0px-2.start {
        counter-reset: lst-ctn-kix_xlfsbi2sm0px-2 0;
    }

    ol.lst-kix_vpxf4k7k6jyd-2 {
        list-style-type: none;
    }

    ol.lst-kix_vpxf4k7k6jyd-1 {
        list-style-type: none;
    }

    ol.lst-kix_vpxf4k7k6jyd-4 {
        list-style-type: none;
    }

    ol.lst-kix_u6orge8dsaaj-6.start {
        counter-reset: lst-ctn-kix_u6orge8dsaaj-6 0;
    }

    ol.lst-kix_vpxf4k7k6jyd-3 {
        list-style-type: none;
    }

    ol.lst-kix_vpxf4k7k6jyd-0 {
        list-style-type: none;
    }

    ol.lst-kix_3i7tngtwyogn-5.start {
        counter-reset: lst-ctn-kix_3i7tngtwyogn-5 0;
    }

    ol.lst-kix_vpxf4k7k6jyd-6 {
        list-style-type: none;
    }

    ol.lst-kix_vpxf4k7k6jyd-5 {
        list-style-type: none;
    }

    .lst-kix_aax8fik85dck-1>li {
        counter-increment: lst-ctn-kix_aax8fik85dck-1;
    }

    ol.lst-kix_vpxf4k7k6jyd-8 {
        list-style-type: none;
    }

    ol.lst-kix_vpxf4k7k6jyd-7 {
        list-style-type: none;
    }

    .lst-kix_fsqokrgcevtn-0>li:before {
        content: "(" counter(lst-ctn-kix_fsqokrgcevtn-0, lower-roman) ") ";
    }

    ol.lst-kix_u6orge8dsaaj-7.start {
        counter-reset: lst-ctn-kix_u6orge8dsaaj-7 0;
    }

    ol.lst-kix_xlfsbi2sm0px-2 {
        list-style-type: none;
    }

    ol.lst-kix_xlfsbi2sm0px-1 {
        list-style-type: none;
    }

    ol.lst-kix_xlfsbi2sm0px-0 {
        list-style-type: none;
    }

    .lst-kix_xlfsbi2sm0px-3>li:before {
        content: "" counter(lst-ctn-kix_xlfsbi2sm0px-1, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-2, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-3, decimal) ". ";
    }

    .lst-kix_xlfsbi2sm0px-7>li:before {
        content: "" counter(lst-ctn-kix_xlfsbi2sm0px-1, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-2, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-3, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-4, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-5, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-6, decimal) "."
            counter(lst-ctn-kix_xlfsbi2sm0px-7, decimal) ". ";
    }

    .lst-kix_nh1bdi7ymzg-8>li {
        counter-increment: lst-ctn-kix_nh1bdi7ymzg-8;
    }

    ol.lst-kix_xlfsbi2sm0px-3.start {
        counter-reset: lst-ctn-kix_xlfsbi2sm0px-3 0;
    }

    .lst-kix_xlfsbi2sm0px-3>li {
        counter-increment: lst-ctn-kix_xlfsbi2sm0px-3;
    }

    .lst-kix_3i7tngtwyogn-0>li {
        counter-increment: lst-ctn-kix_3i7tngtwyogn-0;
    }

    .lst-kix_nz0yms2x52g4-4>li:before {
        content: "(" counter(lst-ctn-kix_nz0yms2x52g4-4, lower-roman) ") ";
    }

    ol.lst-kix_nh1bdi7ymzg-4.start {
        counter-reset: lst-ctn-kix_nh1bdi7ymzg-4 0;
    }

    ol.lst-kix_3i7tngtwyogn-4.start {
        counter-reset: lst-ctn-kix_3i7tngtwyogn-4 0;
    }

    .lst-kix_su3cw9ujxk12-6>li:before {
        content: "" counter(lst-ctn-kix_su3cw9ujxk12-0, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-1, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-2, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-3, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-4, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-5, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-6, decimal) ". ";
    }

    .lst-kix_nz0yms2x52g4-0>li:before {
        content: "(" counter(lst-ctn-kix_nz0yms2x52g4-0, lower-roman) ") ";
    }

    ol.lst-kix_xlfsbi2sm0px-8 {
        list-style-type: none;
    }

    ol.lst-kix_xlfsbi2sm0px-7 {
        list-style-type: none;
    }

    .lst-kix_hyo15tavnzmq-2>li {
        counter-increment: lst-ctn-kix_hyo15tavnzmq-2;
    }

    ol.lst-kix_xlfsbi2sm0px-6 {
        list-style-type: none;
    }

    ol.lst-kix_xlfsbi2sm0px-5 {
        list-style-type: none;
    }

    ol.lst-kix_xlfsbi2sm0px-4 {
        list-style-type: none;
    }

    ol.lst-kix_xlfsbi2sm0px-3 {
        list-style-type: none;
    }

    .lst-kix_nh1bdi7ymzg-1>li {
        counter-increment: lst-ctn-kix_nh1bdi7ymzg-1;
    }

    .lst-kix_g0wb47k0ykcf-6>li {
        counter-increment: lst-ctn-kix_g0wb47k0ykcf-6;
    }

    .lst-kix_fsqokrgcevtn-6>li {
        counter-increment: lst-ctn-kix_fsqokrgcevtn-6;
    }

    .lst-kix_vpxf4k7k6jyd-6>li {
        counter-increment: lst-ctn-kix_vpxf4k7k6jyd-6;
    }

    li.li-bullet-0:before {
        margin-left: -28.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 28.4pt;
    }

    ol.lst-kix_xlfsbi2sm0px-4.start {
        counter-reset: lst-ctn-kix_xlfsbi2sm0px-4 0;
    }

    .lst-kix_su3cw9ujxk12-2>li:before {
        content: "" counter(lst-ctn-kix_su3cw9ujxk12-0, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-1, decimal) "."
            counter(lst-ctn-kix_su3cw9ujxk12-2, decimal) ". ";
    }

    ol.lst-kix_3i7tngtwyogn-3.start {
        counter-reset: lst-ctn-kix_3i7tngtwyogn-3 0;
    }

    ol.lst-kix_1f2sx3bf4x8-4.start {
        counter-reset: lst-ctn-kix_1f2sx3bf4x8-4 0;
    }

    ol {
        margin: 0;
        padding: 0;
    }

    table td,
    table th {
        padding: 0;
    }

    .c12 {
        margin-left: 41.4pt;
        padding-top: 0pt;
        padding-left: 15.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c9 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt;
    }

    .c6 {
        margin-left: 46.4pt;
        padding-top: 0pt;
        padding-left: 10.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c2 {
        margin-left: 18pt;
        padding-top: 0pt;
        padding-left: 10.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c7 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt;
    }

    .c3 {
        margin-left: 28.4pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt;
    }

    .c10 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt;
    }

    .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Book Antiqua";
        font-style: normal;
    }

    .c5 {
        margin-left: 28.4pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c14 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c28 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: center;
    }

    .c1 {
        -webkit-text-decoration-skip: none;
        font-weight: 700;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        font-size: 10pt;
        font-family: "Book Antiqua";
    }

    .c17 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal;
    }

    .c21 {
        color: #000000;
        vertical-align: baseline;
        font-style: normal;
    }

    .c27 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
    }

    .c24 {
        font-weight: 400;
        font-size: 11pt;
        font-family: "Arial";
    }

    .c16 {
        font-size: 10pt;
        font-family: "Book Antiqua";
        font-weight: 400;
    }

    .c8 {
        font-size: 10pt;
        font-family: "Book Antiqua";
        font-weight: 700;
    }

    .c4 {
        padding: 0;
        margin: 0;
    }

    .c11 {
        margin-left: 56.7pt;
        text-indent: -28.4pt;
    }

    .c29 {
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt;
    }

    .c22 {
        margin-left: 64.3pt;
    }

    .c19 {
        background-color: #ffffff;
    }

    .c20 {
        margin-left: 38.7pt;
    }

    .c13 {
        margin-left: 7.1pt;
    }

    .c25 {
        margin-left: 56.7pt;
    }

    .c23 {
        margin-left: 21.3pt;
    }

    .c15 {
        margin-left: 35.5pt;
    }

    .c18 {
        text-indent: -28.4pt;
    }

    .c26 {
        font-style: italic;
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
    }
}