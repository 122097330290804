@import url('https://themes.googleusercontent.com/fonts/css?kit=3qINvnjb346LubKDfLRn69t6G6Z1RrRfgULZ1AHo7mPOfsfM6rvuuu7h1pY3r_-A');

.faqs-content {

    .lst-kix_lne3t1ulzjd8-3>li {
        counter-increment: lst-ctn-kix_lne3t1ulzjd8-3
    }

    ol.lst-kix_lne3t1ulzjd8-7.start {
        counter-reset: lst-ctn-kix_lne3t1ulzjd8-7 0
    }

    .lst-kix_lne3t1ulzjd8-2>li {
        counter-increment: lst-ctn-kix_lne3t1ulzjd8-2
    }

    ol.lst-kix_lne3t1ulzjd8-3.start {
        counter-reset: lst-ctn-kix_lne3t1ulzjd8-3 0
    }

    ol.lst-kix_lne3t1ulzjd8-0.start {
        counter-reset: lst-ctn-kix_lne3t1ulzjd8-0 0
    }

    .lst-kix_lne3t1ulzjd8-8>li {
        counter-increment: lst-ctn-kix_lne3t1ulzjd8-8
    }

    ul.lst-kix_6gn35hk26s3v-0 {
        list-style-type: none
    }

    ol.lst-kix_lne3t1ulzjd8-1.start {
        counter-reset: lst-ctn-kix_lne3t1ulzjd8-1 0
    }

    .lst-kix_lne3t1ulzjd8-4>li {
        counter-increment: lst-ctn-kix_lne3t1ulzjd8-4
    }

    .lst-kix_lne3t1ulzjd8-7>li:before {
        content: "" counter(lst-ctn-kix_lne3t1ulzjd8-7, lower-latin) ". "
    }

    .lst-kix_lne3t1ulzjd8-6>li:before {
        content: "" counter(lst-ctn-kix_lne3t1ulzjd8-6, decimal) ". "
    }

    .lst-kix_lne3t1ulzjd8-5>li:before {
        content: "" counter(lst-ctn-kix_lne3t1ulzjd8-5, lower-roman) ". "
    }

    .lst-kix_lne3t1ulzjd8-7>li {
        counter-increment: lst-ctn-kix_lne3t1ulzjd8-7
    }

    ul.lst-kix_6gn35hk26s3v-8 {
        list-style-type: none
    }

    .lst-kix_lne3t1ulzjd8-3>li:before {
        content: "" counter(lst-ctn-kix_lne3t1ulzjd8-3, decimal) ". "
    }

    ul.lst-kix_6gn35hk26s3v-7 {
        list-style-type: none
    }

    ul.lst-kix_6gn35hk26s3v-6 {
        list-style-type: none
    }

    .lst-kix_lne3t1ulzjd8-2>li:before {
        content: "" counter(lst-ctn-kix_lne3t1ulzjd8-2, lower-roman) ". "
    }

    .lst-kix_lne3t1ulzjd8-4>li:before {
        content: "" counter(lst-ctn-kix_lne3t1ulzjd8-4, lower-latin) ". "
    }

    ul.lst-kix_6gn35hk26s3v-5 {
        list-style-type: none
    }

    ul.lst-kix_6gn35hk26s3v-4 {
        list-style-type: none
    }

    ul.lst-kix_6gn35hk26s3v-3 {
        list-style-type: none
    }

    .lst-kix_ptewniqtbsei-7>li:before {
        content: "\0025cb  "
    }

    ul.lst-kix_6gn35hk26s3v-2 {
        list-style-type: none
    }

    ol.lst-kix_lne3t1ulzjd8-4.start {
        counter-reset: lst-ctn-kix_lne3t1ulzjd8-4 0
    }

    ul.lst-kix_6gn35hk26s3v-1 {
        list-style-type: none
    }

    .lst-kix_ptewniqtbsei-8>li:before {
        content: "\0025a0  "
    }

    .lst-kix_lne3t1ulzjd8-6>li {
        counter-increment: lst-ctn-kix_lne3t1ulzjd8-6
    }

    .lst-kix_lne3t1ulzjd8-0>li:before {
        content: "" counter(lst-ctn-kix_lne3t1ulzjd8-0, decimal) ". "
    }

    .lst-kix_ptewniqtbsei-6>li:before {
        content: "\0025cf  "
    }

    .lst-kix_lne3t1ulzjd8-1>li:before {
        content: "" counter(lst-ctn-kix_lne3t1ulzjd8-1, lower-latin) ". "
    }

    .lst-kix_ptewniqtbsei-5>li:before {
        content: "\0025a0  "
    }

    ul.lst-kix_ptewniqtbsei-3 {
        list-style-type: none
    }

    .lst-kix_ptewniqtbsei-4>li:before {
        content: "\0025cb  "
    }

    ul.lst-kix_ptewniqtbsei-2 {
        list-style-type: none
    }

    ul.lst-kix_ptewniqtbsei-1 {
        list-style-type: none
    }

    ul.lst-kix_ptewniqtbsei-0 {
        list-style-type: none
    }

    ul.lst-kix_ptewniqtbsei-7 {
        list-style-type: none
    }

    ol.lst-kix_lne3t1ulzjd8-2.start {
        counter-reset: lst-ctn-kix_lne3t1ulzjd8-2 0
    }

    ul.lst-kix_ptewniqtbsei-6 {
        list-style-type: none
    }

    .lst-kix_ptewniqtbsei-3>li:before {
        content: "\0025cf  "
    }

    ul.lst-kix_ptewniqtbsei-5 {
        list-style-type: none
    }

    ul.lst-kix_ptewniqtbsei-4 {
        list-style-type: none
    }

    .lst-kix_ptewniqtbsei-0>li:before {
        content: "\0025cf  "
    }

    .lst-kix_lne3t1ulzjd8-1>li {
        counter-increment: lst-ctn-kix_lne3t1ulzjd8-1
    }

    .lst-kix_lne3t1ulzjd8-8>li:before {
        content: "" counter(lst-ctn-kix_lne3t1ulzjd8-8, lower-roman) ". "
    }

    ul.lst-kix_ptewniqtbsei-8 {
        list-style-type: none
    }

    .lst-kix_ptewniqtbsei-2>li:before {
        content: "\0025a0  "
    }

    ol.lst-kix_lne3t1ulzjd8-5.start {
        counter-reset: lst-ctn-kix_lne3t1ulzjd8-5 0
    }

    .lst-kix_ptewniqtbsei-1>li:before {
        content: "\0025cb  "
    }

    .lst-kix_6gn35hk26s3v-0>li:before {
        content: "\0025cf  "
    }

    .lst-kix_6gn35hk26s3v-1>li:before {
        content: "\0025cb  "
    }

    .lst-kix_lne3t1ulzjd8-5>li {
        counter-increment: lst-ctn-kix_lne3t1ulzjd8-5
    }

    .lst-kix_6gn35hk26s3v-3>li:before {
        content: "\0025cf  "
    }

    ol.lst-kix_lne3t1ulzjd8-8.start {
        counter-reset: lst-ctn-kix_lne3t1ulzjd8-8 0
    }

    .lst-kix_6gn35hk26s3v-2>li:before {
        content: "\0025a0  "
    }

    .lst-kix_6gn35hk26s3v-5>li:before {
        content: "\0025a0  "
    }

    ol.lst-kix_lne3t1ulzjd8-0 {
        list-style-type: none
    }

    ol.lst-kix_lne3t1ulzjd8-1 {
        list-style-type: none
    }

    ol.lst-kix_lne3t1ulzjd8-2 {
        list-style-type: none
    }

    ol.lst-kix_lne3t1ulzjd8-3 {
        list-style-type: none
    }

    ol.lst-kix_lne3t1ulzjd8-4 {
        list-style-type: none
    }

    .lst-kix_6gn35hk26s3v-4>li:before {
        content: "\0025cb  "
    }

    .lst-kix_6gn35hk26s3v-8>li:before {
        content: "\0025a0  "
    }

    ol.lst-kix_lne3t1ulzjd8-5 {
        list-style-type: none
    }

    ol.lst-kix_lne3t1ulzjd8-6 {
        list-style-type: none
    }

    ol.lst-kix_lne3t1ulzjd8-7 {
        list-style-type: none
    }

    ol.lst-kix_lne3t1ulzjd8-8 {
        list-style-type: none
    }

    .lst-kix_6gn35hk26s3v-7>li:before {
        content: "\0025cb  "
    }

    li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    .lst-kix_6gn35hk26s3v-6>li:before {
        content: "\0025cf  "
    }

    ol.lst-kix_lne3t1ulzjd8-6.start {
        counter-reset: lst-ctn-kix_lne3t1ulzjd8-6 0
    }

    .lst-kix_lne3t1ulzjd8-0>li {
        counter-increment: lst-ctn-kix_lne3t1ulzjd8-0
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td,
    table th {
        padding: 0
    }

    .c0 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c5 {
        padding-top: 0pt;
        text-indent: 36pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c8 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c4 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-style: normal
    }

    .c10 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 14pt;
        font-style: normal
    }

    .c13 {
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        text-decoration: underline;
        text-decoration-skip-ink: none
    }

    .c12 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt
    }

    .c14 {
        background-color: #ffffff;
        font-size: 10.5pt
    }

    .c6 {
        padding: 0;
        margin: 0
    }

    .c1 {
        font-weight: 400;
        font-family: "Proxima Nova"
    }

    .c7 {
        font-weight: 700;
        font-family: "Proxima Nova"
    }

    .c9 {
        color: inherit;
        text-decoration: inherit
    }

    .c11 {
        margin-left: 72pt
    }

    .c2 {
        height: 11pt
    }

    .c3 {
        padding-left: 0pt
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }
    a.c9-custom {
        color: #1155cc;
        text-decoration: underline !important;
        font-weight: 700;
    }
}