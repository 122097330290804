@import url("https://themes.googleusercontent.com/fonts/css?kit=hh9VFM0qOK4TrLidDd__Ui5bnSrpY5TE94xSqAiQW90ySt5OsLYwI6nQPZqO1_t4");

.privacy-content {
    ol.lst-kix_y78o8pdvzzyp-3.start {
        counter-reset: lst-ctn-kix_y78o8pdvzzyp-3 0;
    }

    .lst-kix_y78o8pdvzzyp-4>li {
        counter-increment: lst-ctn-kix_y78o8pdvzzyp-4;
    }

    .lst-kix_u0cqxtlwqbko-4>li {
        counter-increment: lst-ctn-kix_u0cqxtlwqbko-4;
    }

    ol.lst-kix_yzq87a9m9v8u-3.start {
        counter-reset: lst-ctn-kix_yzq87a9m9v8u-3 0;
    }

    .lst-kix_yzq87a9m9v8u-5>li {
        counter-increment: lst-ctn-kix_yzq87a9m9v8u-5;
    }

    ol.lst-kix_yzq87a9m9v8u-1 {
        list-style-type: none;
    }

    ol.lst-kix_yzq87a9m9v8u-2 {
        list-style-type: none;
    }

    ol.lst-kix_6rno7yvcjofv-1.start {
        counter-reset: lst-ctn-kix_6rno7yvcjofv-1 0;
    }

    ol.lst-kix_yzq87a9m9v8u-0 {
        list-style-type: none;
    }

    ol.lst-kix_yzq87a9m9v8u-5 {
        list-style-type: none;
    }

    ol.lst-kix_yzq87a9m9v8u-6 {
        list-style-type: none;
    }

    ol.lst-kix_yzq87a9m9v8u-3 {
        list-style-type: none;
    }

    ol.lst-kix_u0cqxtlwqbko-1 {
        list-style-type: none;
    }

    ol.lst-kix_yzq87a9m9v8u-4 {
        list-style-type: none;
    }

    ol.lst-kix_u0cqxtlwqbko-0 {
        list-style-type: none;
    }

    ol.lst-kix_y78o8pdvzzyp-0.start {
        counter-reset: lst-ctn-kix_y78o8pdvzzyp-0 0;
    }

    ol.lst-kix_yzq87a9m9v8u-6.start {
        counter-reset: lst-ctn-kix_yzq87a9m9v8u-6 0;
    }

    .lst-kix_yzq87a9m9v8u-6>li {
        counter-increment: lst-ctn-kix_yzq87a9m9v8u-6;
    }

    ol.lst-kix_6rno7yvcjofv-8 {
        list-style-type: none;
    }

    ol.lst-kix_6rno7yvcjofv-7 {
        list-style-type: none;
    }

    ol.lst-kix_6rno7yvcjofv-6 {
        list-style-type: none;
    }

    .lst-kix_y78o8pdvzzyp-0>li:before {
        content: "" counter(lst-ctn-kix_y78o8pdvzzyp-0, decimal) ". ";
    }

    .lst-kix_y78o8pdvzzyp-2>li:before {
        content: "" counter(lst-ctn-kix_y78o8pdvzzyp-2, decimal) ". ";
    }

    .lst-kix_y78o8pdvzzyp-3>li:before {
        content: "" counter(lst-ctn-kix_y78o8pdvzzyp-3, decimal) ". ";
    }

    .lst-kix_u0cqxtlwqbko-5>li {
        counter-increment: lst-ctn-kix_u0cqxtlwqbko-5;
    }

    .lst-kix_yzq87a9m9v8u-7>li {
        counter-increment: lst-ctn-kix_yzq87a9m9v8u-7;
    }

    .lst-kix_yzq87a9m9v8u-4>li {
        counter-increment: lst-ctn-kix_yzq87a9m9v8u-4;
    }

    ol.lst-kix_6rno7yvcjofv-4.start {
        counter-reset: lst-ctn-kix_6rno7yvcjofv-4 0;
    }

    ol.lst-kix_yzq87a9m9v8u-0.start {
        counter-reset: lst-ctn-kix_yzq87a9m9v8u-0 1;
    }

    .lst-kix_y78o8pdvzzyp-5>li {
        counter-increment: lst-ctn-kix_y78o8pdvzzyp-5;
    }

    ol.lst-kix_u0cqxtlwqbko-1.start {
        counter-reset: lst-ctn-kix_u0cqxtlwqbko-1 0;
    }

    .lst-kix_y78o8pdvzzyp-1>li:before {
        content: "" counter(lst-ctn-kix_y78o8pdvzzyp-1, decimal) ". ";
    }

    .lst-kix_u0cqxtlwqbko-3>li {
        counter-increment: lst-ctn-kix_u0cqxtlwqbko-3;
    }

    ol.lst-kix_u0cqxtlwqbko-3 {
        list-style-type: none;
    }

    .lst-kix_y78o8pdvzzyp-8>li:before {
        content: "" counter(lst-ctn-kix_y78o8pdvzzyp-8, decimal) ". ";
    }

    ol.lst-kix_u0cqxtlwqbko-2 {
        list-style-type: none;
    }

    ol.lst-kix_yzq87a9m9v8u-7 {
        list-style-type: none;
    }

    ol.lst-kix_u0cqxtlwqbko-5 {
        list-style-type: none;
    }

    ol.lst-kix_yzq87a9m9v8u-8 {
        list-style-type: none;
    }

    ol.lst-kix_u0cqxtlwqbko-4 {
        list-style-type: none;
    }

    ol.lst-kix_u0cqxtlwqbko-7 {
        list-style-type: none;
    }

    .lst-kix_y78o8pdvzzyp-2>li {
        counter-increment: lst-ctn-kix_y78o8pdvzzyp-2;
    }

    .lst-kix_y78o8pdvzzyp-6>li:before {
        content: "" counter(lst-ctn-kix_y78o8pdvzzyp-6, decimal) ". ";
    }

    ol.lst-kix_u0cqxtlwqbko-6 {
        list-style-type: none;
    }

    .lst-kix_6rno7yvcjofv-8>li {
        counter-increment: lst-ctn-kix_6rno7yvcjofv-8;
    }

    .lst-kix_y78o8pdvzzyp-7>li:before {
        content: "" counter(lst-ctn-kix_y78o8pdvzzyp-7, decimal) ". ";
    }

    ol.lst-kix_u0cqxtlwqbko-8 {
        list-style-type: none;
    }

    .lst-kix_y78o8pdvzzyp-4>li:before {
        content: "" counter(lst-ctn-kix_y78o8pdvzzyp-4, decimal) ". ";
    }

    ol.lst-kix_u0cqxtlwqbko-4.start {
        counter-reset: lst-ctn-kix_u0cqxtlwqbko-4 0;
    }

    ol.lst-kix_u0cqxtlwqbko-7.start {
        counter-reset: lst-ctn-kix_u0cqxtlwqbko-7 0;
    }

    ol.lst-kix_6rno7yvcjofv-7.start {
        counter-reset: lst-ctn-kix_6rno7yvcjofv-7 0;
    }

    .lst-kix_y78o8pdvzzyp-5>li:before {
        content: "" counter(lst-ctn-kix_y78o8pdvzzyp-5, decimal) ". ";
    }

    .lst-kix_y78o8pdvzzyp-7>li {
        counter-increment: lst-ctn-kix_y78o8pdvzzyp-7;
    }

    .lst-kix_y78o8pdvzzyp-1>li {
        counter-increment: lst-ctn-kix_y78o8pdvzzyp-1;
    }

    ol.lst-kix_6rno7yvcjofv-3.start {
        counter-reset: lst-ctn-kix_6rno7yvcjofv-3 0;
    }

    .lst-kix_yzq87a9m9v8u-2>li {
        counter-increment: lst-ctn-kix_yzq87a9m9v8u-2;
    }

    ol.lst-kix_yzq87a9m9v8u-8.start {
        counter-reset: lst-ctn-kix_yzq87a9m9v8u-8 0;
    }

    .lst-kix_u0cqxtlwqbko-1>li {
        counter-increment: lst-ctn-kix_u0cqxtlwqbko-1;
    }

    .lst-kix_6rno7yvcjofv-0>li {
        counter-increment: lst-ctn-kix_6rno7yvcjofv-0;
    }

    ol.lst-kix_yzq87a9m9v8u-1.start {
        counter-reset: lst-ctn-kix_yzq87a9m9v8u-1 0;
    }

    .lst-kix_6rno7yvcjofv-6>li {
        counter-increment: lst-ctn-kix_6rno7yvcjofv-6;
    }

    ol.lst-kix_u0cqxtlwqbko-8.start {
        counter-reset: lst-ctn-kix_u0cqxtlwqbko-8 0;
    }

    ol.lst-kix_y78o8pdvzzyp-5.start {
        counter-reset: lst-ctn-kix_y78o8pdvzzyp-5 0;
    }

    ol.lst-kix_yzq87a9m9v8u-2.start {
        counter-reset: lst-ctn-kix_yzq87a9m9v8u-2 0;
    }

    ol.lst-kix_y78o8pdvzzyp-6.start {
        counter-reset: lst-ctn-kix_y78o8pdvzzyp-6 0;
    }

    ol.lst-kix_u0cqxtlwqbko-2.start {
        counter-reset: lst-ctn-kix_u0cqxtlwqbko-2 0;
    }

    ol.lst-kix_6rno7yvcjofv-2.start {
        counter-reset: lst-ctn-kix_6rno7yvcjofv-2 0;
    }

    .lst-kix_yzq87a9m9v8u-8>li {
        counter-increment: lst-ctn-kix_yzq87a9m9v8u-8;
    }

    .lst-kix_u0cqxtlwqbko-7>li {
        counter-increment: lst-ctn-kix_u0cqxtlwqbko-7;
    }

    ol.lst-kix_y78o8pdvzzyp-5 {
        list-style-type: none;
    }

    ol.lst-kix_y78o8pdvzzyp-6 {
        list-style-type: none;
    }

    ol.lst-kix_y78o8pdvzzyp-7 {
        list-style-type: none;
    }

    ol.lst-kix_y78o8pdvzzyp-8 {
        list-style-type: none;
    }

    ol.lst-kix_u0cqxtlwqbko-0.start {
        counter-reset: lst-ctn-kix_u0cqxtlwqbko-0 0;
    }

    ol.lst-kix_y78o8pdvzzyp-1 {
        list-style-type: none;
    }

    ol.lst-kix_y78o8pdvzzyp-2 {
        list-style-type: none;
    }

    ol.lst-kix_y78o8pdvzzyp-3 {
        list-style-type: none;
    }

    ol.lst-kix_6rno7yvcjofv-8.start {
        counter-reset: lst-ctn-kix_6rno7yvcjofv-8 0;
    }

    ol.lst-kix_y78o8pdvzzyp-4 {
        list-style-type: none;
    }

    .lst-kix_6rno7yvcjofv-0>li:before {
        content: "" counter(lst-ctn-kix_6rno7yvcjofv-0, decimal) ". ";
    }

    .lst-kix_6rno7yvcjofv-1>li:before {
        content: "" counter(lst-ctn-kix_6rno7yvcjofv-1, decimal) ". ";
    }

    .lst-kix_6rno7yvcjofv-3>li {
        counter-increment: lst-ctn-kix_6rno7yvcjofv-3;
    }

    .lst-kix_6rno7yvcjofv-2>li:before {
        content: "(" counter(lst-ctn-kix_6rno7yvcjofv-2, lower-roman) ") ";
    }

    ol.lst-kix_6rno7yvcjofv-5.start {
        counter-reset: lst-ctn-kix_6rno7yvcjofv-5 0;
    }

    .lst-kix_yzq87a9m9v8u-0>li {
        counter-increment: lst-ctn-kix_yzq87a9m9v8u-0;
    }

    ol.lst-kix_y78o8pdvzzyp-7.start {
        counter-reset: lst-ctn-kix_y78o8pdvzzyp-7 0;
    }

    ol.lst-kix_y78o8pdvzzyp-0 {
        list-style-type: none;
    }

    ol.lst-kix_u0cqxtlwqbko-3.start {
        counter-reset: lst-ctn-kix_u0cqxtlwqbko-3 0;
    }

    .lst-kix_yzq87a9m9v8u-8>li:before {
        content: "" counter(lst-ctn-kix_yzq87a9m9v8u-8, decimal) ". ";
    }

    .lst-kix_yzq87a9m9v8u-7>li:before {
        content: "" counter(lst-ctn-kix_yzq87a9m9v8u-7, decimal) ". ";
    }

    .lst-kix_yzq87a9m9v8u-6>li:before {
        content: "" counter(lst-ctn-kix_yzq87a9m9v8u-6, decimal) ". ";
    }

    .lst-kix_yzq87a9m9v8u-1>li {
        counter-increment: lst-ctn-kix_yzq87a9m9v8u-1;
    }

    li.li-bullet-1:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt;
    }

    .lst-kix_6rno7yvcjofv-8>li:before {
        content: "" counter(lst-ctn-kix_6rno7yvcjofv-8, decimal) ". ";
    }

    .lst-kix_y78o8pdvzzyp-8>li {
        counter-increment: lst-ctn-kix_y78o8pdvzzyp-8;
    }

    ol.lst-kix_yzq87a9m9v8u-4.start {
        counter-reset: lst-ctn-kix_yzq87a9m9v8u-4 0;
    }

    .lst-kix_u0cqxtlwqbko-8>li {
        counter-increment: lst-ctn-kix_u0cqxtlwqbko-8;
    }

    ol.lst-kix_y78o8pdvzzyp-4.start {
        counter-reset: lst-ctn-kix_y78o8pdvzzyp-4 0;
    }

    .lst-kix_yzq87a9m9v8u-0>li:before {
        content: "" counter(lst-ctn-kix_yzq87a9m9v8u-0, decimal) ". ";
    }

    .lst-kix_6rno7yvcjofv-4>li:before {
        content: "" counter(lst-ctn-kix_6rno7yvcjofv-4, decimal) ". ";
    }

    .lst-kix_6rno7yvcjofv-5>li:before {
        content: "" counter(lst-ctn-kix_6rno7yvcjofv-5, decimal) ". ";
    }

    .lst-kix_yzq87a9m9v8u-1>li:before {
        content: "" counter(lst-ctn-kix_yzq87a9m9v8u-1, decimal) ". ";
    }

    .lst-kix_6rno7yvcjofv-5>li {
        counter-increment: lst-ctn-kix_6rno7yvcjofv-5;
    }

    .lst-kix_yzq87a9m9v8u-2>li:before {
        content: "" counter(lst-ctn-kix_yzq87a9m9v8u-2, decimal) ". ";
    }

    .lst-kix_u0cqxtlwqbko-0>li {
        counter-increment: lst-ctn-kix_u0cqxtlwqbko-0;
    }

    .lst-kix_6rno7yvcjofv-3>li:before {
        content: "" counter(lst-ctn-kix_6rno7yvcjofv-3, decimal) ". ";
    }

    .lst-kix_6rno7yvcjofv-7>li:before {
        content: "" counter(lst-ctn-kix_6rno7yvcjofv-7, decimal) ". ";
    }

    .lst-kix_yzq87a9m9v8u-4>li:before {
        content: "" counter(lst-ctn-kix_yzq87a9m9v8u-4, decimal) ". ";
    }

    ol.lst-kix_yzq87a9m9v8u-7.start {
        counter-reset: lst-ctn-kix_yzq87a9m9v8u-7 0;
    }

    .lst-kix_yzq87a9m9v8u-3>li:before {
        content: "" counter(lst-ctn-kix_yzq87a9m9v8u-3, decimal) ". ";
    }

    .lst-kix_yzq87a9m9v8u-5>li:before {
        content: "" counter(lst-ctn-kix_yzq87a9m9v8u-5, decimal) ". ";
    }

    .lst-kix_6rno7yvcjofv-6>li:before {
        content: "" counter(lst-ctn-kix_6rno7yvcjofv-6, decimal) ". ";
    }

    ol.lst-kix_y78o8pdvzzyp-1.start {
        counter-reset: lst-ctn-kix_y78o8pdvzzyp-1 0;
    }

    .lst-kix_6rno7yvcjofv-2>li {
        counter-increment: lst-ctn-kix_6rno7yvcjofv-2;
    }

    ol.lst-kix_u0cqxtlwqbko-5.start {
        counter-reset: lst-ctn-kix_u0cqxtlwqbko-5 0;
    }

    .lst-kix_u0cqxtlwqbko-8>li:before {
        content: "" counter(lst-ctn-kix_u0cqxtlwqbko-8, decimal) ". ";
    }

    .lst-kix_u0cqxtlwqbko-7>li:before {
        content: "" counter(lst-ctn-kix_u0cqxtlwqbko-7, decimal) ". ";
    }

    ol.lst-kix_y78o8pdvzzyp-8.start {
        counter-reset: lst-ctn-kix_y78o8pdvzzyp-8 0;
    }

    .lst-kix_u0cqxtlwqbko-4>li:before {
        content: "" counter(lst-ctn-kix_u0cqxtlwqbko-4, decimal) ". ";
    }

    ol.lst-kix_6rno7yvcjofv-0.start {
        counter-reset: lst-ctn-kix_6rno7yvcjofv-0 0;
    }

    ol.lst-kix_6rno7yvcjofv-6.start {
        counter-reset: lst-ctn-kix_6rno7yvcjofv-6 0;
    }

    .lst-kix_yzq87a9m9v8u-3>li {
        counter-increment: lst-ctn-kix_yzq87a9m9v8u-3;
    }

    .lst-kix_u0cqxtlwqbko-6>li {
        counter-increment: lst-ctn-kix_u0cqxtlwqbko-6;
    }

    .lst-kix_u0cqxtlwqbko-2>li:before {
        content: "(" counter(lst-ctn-kix_u0cqxtlwqbko-2, lower-roman) ") ";
    }

    .lst-kix_u0cqxtlwqbko-6>li:before {
        content: "" counter(lst-ctn-kix_u0cqxtlwqbko-6, decimal) ". ";
    }

    .lst-kix_u0cqxtlwqbko-1>li:before {
        content: "" counter(lst-ctn-kix_u0cqxtlwqbko-1, decimal) ". ";
    }

    .lst-kix_u0cqxtlwqbko-5>li:before {
        content: "" counter(lst-ctn-kix_u0cqxtlwqbko-5, decimal) ". ";
    }

    .lst-kix_u0cqxtlwqbko-2>li {
        counter-increment: lst-ctn-kix_u0cqxtlwqbko-2;
    }

    .lst-kix_u0cqxtlwqbko-3>li:before {
        content: "" counter(lst-ctn-kix_u0cqxtlwqbko-3, decimal) ". ";
    }

    ol.lst-kix_6rno7yvcjofv-5 {
        list-style-type: none;
    }

    .lst-kix_6rno7yvcjofv-7>li {
        counter-increment: lst-ctn-kix_6rno7yvcjofv-7;
    }

    ol.lst-kix_6rno7yvcjofv-4 {
        list-style-type: none;
    }

    ol.lst-kix_6rno7yvcjofv-3 {
        list-style-type: none;
    }

    .lst-kix_6rno7yvcjofv-1>li {
        counter-increment: lst-ctn-kix_6rno7yvcjofv-1;
    }

    ol.lst-kix_6rno7yvcjofv-2 {
        list-style-type: none;
    }

    ol.lst-kix_6rno7yvcjofv-1 {
        list-style-type: none;
    }

    ol.lst-kix_u0cqxtlwqbko-6.start {
        counter-reset: lst-ctn-kix_u0cqxtlwqbko-6 0;
    }

    ol.lst-kix_6rno7yvcjofv-0 {
        list-style-type: none;
    }

    ol.lst-kix_y78o8pdvzzyp-2.start {
        counter-reset: lst-ctn-kix_y78o8pdvzzyp-2 0;
    }

    .lst-kix_6rno7yvcjofv-4>li {
        counter-increment: lst-ctn-kix_6rno7yvcjofv-4;
    }

    .lst-kix_u0cqxtlwqbko-0>li:before {
        content: "" counter(lst-ctn-kix_u0cqxtlwqbko-0, decimal) ". ";
    }

    .lst-kix_y78o8pdvzzyp-0>li {
        counter-increment: lst-ctn-kix_y78o8pdvzzyp-0;
    }

    .lst-kix_y78o8pdvzzyp-3>li {
        counter-increment: lst-ctn-kix_y78o8pdvzzyp-3;
    }

    li.li-bullet-0:before {
        margin-left: -28.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 28.4pt;
    }

    ol.lst-kix_yzq87a9m9v8u-5.start {
        counter-reset: lst-ctn-kix_yzq87a9m9v8u-5 0;
    }

    .lst-kix_y78o8pdvzzyp-6>li {
        counter-increment: lst-ctn-kix_y78o8pdvzzyp-6;
    }

    ol {
        margin: 0;
        padding: 0;
    }

    table td,
    table th {
        padding: 0;
    }

    .c6 {
        background-color: #ffffff;
        margin-left: 18pt;
        padding-top: 0pt;
        padding-left: 10.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c12 {
        margin-left: 36pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt;
    }

    .c2 {
        margin-left: 28.4pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt;
    }

    .c7 {
        background-color: #ffffff;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt;
    }

    .c10 {
        margin-left: 56.7pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt;
    }

    .c11 {
        margin-left: 28.4pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c1 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Book Antiqua";
        font-style: normal;
    }

    .c3 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Book Antiqua";
        font-style: italic;
    }

    .c18 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt;
    }

    .c13 {
        margin-left: 56.7pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c0 {
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        font-weight: 700;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        font-size: 10pt;
        font-family: "Book Antiqua";
    }

    .c31 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt;
    }

    .c21 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c17 {
        -webkit-text-decoration-skip: none;
        color: #000000;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-style: normal;
    }

    .c24 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    .c23 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: center;
    }

    .c8 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal;
    }

    .c5 {
        font-size: 10pt;
        font-family: "Book Antiqua";
        font-weight: 400;
    }

    .c22 {
        -webkit-text-decoration-skip: none;
        text-decoration: underline;
        text-decoration-skip-ink: none;
    }

    .c19 {
        font-weight: 400;
        font-size: 11pt;
        font-family: "Arial";
    }

    .c25 {
        font-weight: 400;
        font-size: 12pt;
        font-family: "Cambria";
    }

    .c4 {
        font-size: 10pt;
        font-family: "Book Antiqua";
        font-weight: 700;
    }

    .c14 {
        padding: 0;
        margin: 0;
    }

    .c26 {
        margin-left: 38.7pt;
        padding-left: 18pt;
    }

    .c32 {
        margin-left: 56.7pt;
        height: 11pt;
    }

    .c9 {
        color: inherit;
        text-decoration: inherit;
    }

    .c29 {
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt;
    }

    .c27 {
        margin-left: 46.4pt;
        padding-left: 10.4pt;
    }

    .c30 {
        color: #0000ee;
    }

    .c15 {
        font-style: italic;
    }

    .c33 {
        height: 11pt;
    }

    .c28 {
        margin-left: 18pt;
    }

    .c16 {
        margin-left: 21.3pt;
    }

    .c20 {
        background-color: #ffffff;
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
    }
    a.c9-custom {
        color: #1155cc;
        text-decoration: underline !important;
        font-weight: 700;
    }
}