@font-face {
    font-family: "neue_haas_grotesk_disp55roman";
    src: url("./static/fonts/nhaasgroteskdspro-55rg-webfont.woff2") format("woff2"),
        url("./static/fonts/nhaasgroteskdspro-55rg-webfont.woff") format("woff"),
        url("./static/fonts/nhaasgroteskdspro-55rg-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "neue_haas_grotesk_display75Bd";
    src: url("./static/fonts/nhaasgroteskdspro-75bd-webfont.woff2") format("woff2"),
        url("./static/fonts/../fonts/nhaasgroteskdspro-75bd-webfont.woff") format("woff"),
        url("./static/fonts/nhaasgroteskdspro-75bd-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "neue_haas_grotesk_display65Md";
    src: url("./static/fonts/nhaasgroteskdspro-65md-webfont.woff2") format("woff2"),
        url("./static/fonts/nhaasgroteskdspro-65md-webfont.woff") format("woff"),
        url("./static/fonts/nhaasgroteskdspro-65md-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "neue_haas_grotesk_display75Bd";
    src: url("./static/fonts/nhaasgroteskdspro-75bd-webfont.woff2") format("woff2"),
        url("./static/fonts/../fonts/nhaasgroteskdspro-75bd-webfont.woff") format("woff"),
        url("./static/fonts/nhaasgroteskdspro-75bd-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}